@font-face {
  font-family: 'Inter';
  src: local('Inter'), url("./assets/fonts/Inter-Bold.ttf") format('opentype');
 }

 @font-face {
  font-family: 'EXEPixelPerfect';
  src: local('EXEPixelPerfect'), url("./assets/fonts/EXEPixelPerfect.ttf") format('opentype');
 }

.App {
  /* text-align: -webkit-center; */
  font-family: "EXEPixelPerfect";
}

.container {
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
}

.container-header {
  /* font-family: "Cantarell"; */
  color: #F6F6F6;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 20px;
  vertical-align: middle;
}

.App-link {
  color: #2c3e50;
}

::-webkit-scrollbar-thumb {
  background-image: url("./assets/images/download.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #523a3a;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
::-webkit-scrollbar {
  width: 6px;
  height: 0;
}
::-webkit-scrollbar-track {
  background: #242424;
  border: 2px #242424 solid;
}

.Header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  justify-items: center;
  padding-left: 30px;
  padding-right: 30px;
  
}

.Header-logo{
  vertical-align: middle;
  padding-top: 30px;
  
}