.tier-header {
  text-transform: uppercase;
  font-family: "Inter";
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 12px;
  color: white;
}

.rarity-linear-purpure {
  background: linear-gradient(90deg, #854AFF 0%, #F0C87B 100%);
}

.rarity-linear-gray {
  background: linear-gradient(90deg, #8C8C8C 0%, #88F070 100%);
}

.rarity-linear-green {
  background: linear-gradient(90deg, #88F070 0%, #50B9FF 100%);
}

.rarity-linear-blue {
  background: linear-gradient(90deg, #50B9FF 0%, #854AFF 100%);
}

.rarity-linear-red {
  background: linear-gradient(90deg, #ebb512 0%, #FF0050 100%);
}

.rarity-linear {
  height: 2px;
  width: 37px;
  margin-top: 30px;
}

.rarity {
  border-radius: 16px;
  padding: 20px;
  max-width: 1060px;
  margin: 20px;
}


.rarity-list {
  display: flex;
  justify-content: space-between;
}

.rarity-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 64px;
  white-space: nowrap;
  font-weight: 700;
  font-size: 16px;
  background-size: 100% 100% !important;
  color: rgb(220, 220, 220);
  opacity: 0.7;
}
@media (max-width: 600px) {
  .rarity-badge {
    font-size: 0px;
  }
}