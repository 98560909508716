
.tabs {

  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #fff;
  font-size: auto;
  text-decoration : underline;
  text-underline-offset: 10px;
  padding-bottom: 15px;
}

.tabs .tab {
  cursor: pointer;
  font-family: "EXEPixelPerfect";
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
  color: #7e5656;
  text-transform: uppercase;
  transition: all 0.2s;
}

.tabs .tab.active {
  background: #3a2828;
  box-shadow: 0px 2px 12px rgba(24, 17, 17, 0.5), inset 0px 0px 18px #453535;
  border-radius: 10px;
  color: #F6F6F6;
}

.card-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  justify-items: center;
  
}

.input-group {
  display: flex;
  border-radius: 10px;
  background: #242424;
  border: 2px solid transparent;
  align-items: center;
  flex-wrap: nowrap !important;
}

.input-group .input-text, .input-group input {
  margin: 10px;
  text-transform: uppercase;
  outline: none !important;
  background: #242424;
  border: none;
  color: #ffffff;
  font-family: "EXEPixelPerfect";
  font-weight: 700;
  font-size: 20px;
  line-height: 152%;
}

.input-group .input-d {
  border-left: 2px solid #242424;
  display: flex;
  align-items: center;
}

.input-group .input-icon-pen {
  margin-left: 10px;
}

.input-group .input-icon {
  display: block;
  width: 24px;
  height: 24px;
}
input {
  width: 60px;
}

.white{
  color: #7e5656;
}

